import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Animated } from 'react-animated-css';
import { FaCog, FaRecycle, FaPoll } from 'react-icons/fa';
import styled from 'styled-components';

import Container from './Container';
import TitleCard from './TitleCard';
import { PhotoCover } from './PhotoCover';

const Card = styled.div.attrs(() => ({
  className: 'px-3 py-2',
}))`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border: 2px solid #3182ce;
  border-top-right-radius: 20%;
  border-radus: 10px;

  @media (max-width: 870px) {
    border-top-right-radius: 0;
    border-radius: 10px;
  }

  & h3 {
    padding: 2rem 1rem;
    font-size: 2.3rem;
  }

  & svg {
    width: 100%;
    color: #2c5282;
  }

  & div {
    display: flex;
    align-items: center;
  }

  & p {
    font-weight: 500;
    padding: 1rem 1rem;
    text-align: justify;
  }
`;

const Services = () => {
  const data = useStaticQuery(graphql`
    query {
      cover: file(relativePath: { eq: "services.jpg" }) {
        publicURL
      }
    }
  `);

  return (
    <section className="mx-auto pb-10">
      <PhotoCover src={data.cover.publicURL} />
      <Container>
        <Animated animationIn="fadeInDown" animationInDuration={1400}>
          <TitleCard title="Services" />
        </Animated>
        <div className="grid grid-cols-1 mx-5 mid:mx-0 mid:grid-cols-3 my-10 gap-4">
          <Card>
            <h3>Recycling</h3>
            <FaRecycle className="text-8xl" />
            <p>
              We recycle metals from aluminum to zinc, as we continuously
              enhance our efficiency by investing in modern machinery, including
              shears, balers, shredders and granulators.
            </p>
          </Card>
          <Card>
            <h3>Processing</h3>
            <FaCog className="text-8xl" />
            <p>
              We specialize in processing secondary aluminum & zinc alloy
              ingots. Our dedicated workforce collects, sorts, cleans and
              process scrap material from our wide network of suppliers across
              the region.
            </p>
          </Card>
          <Card>
            <h3>Trading</h3>
            <FaPoll className="text-8xl" />
            <p>
              Our team of experts handle the trading of secondary scrap metals
              to our clients worldwide.
            </p>
          </Card>
        </div>
      </Container>
    </section>
  );
};

export default Services;
