/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const PhotoCover = styled.div`
  background: url(${props => props.src});
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 30rem;

  overflow: hidden;
`;
