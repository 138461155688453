import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Line = styled.div`
  display: block;
  height: 3px;
  margin: 0 auto;
  width: ${props => props.width || '40%'};
  background: #2b6cb0;
`;

const TitleContainer = styled.div`
  display: block;
  margin: 0 auto;
  margin-top: ${props => props.mt || -70}px;
  width: 100%;
  max-width: ${props => props.maxwidth || 30}rem;
  padding: 2.4rem;
  color: #2b6cb0;
  background: #fff;
  box-shadow: 0 6px 6px -3px #2a4365;
  border-radius: 5px;
  overflow: hidden;
  box-sizing: border-box;
  @media (max-width: 634px) {
    max-width: 25rem;
  }
`;

const TitleCard = ({ title, lineWidth, maxWidth, marginTop, fontSize }) => (
  <TitleContainer mt={marginTop || null} maxwidth={maxWidth || null}>
    <p
      className={`text-2xl sm:${
        fontSize ? `text-${fontSize}` : 'text-5xl'
      } text-center my-0 uppercase tracking-widest mx-auto`}
    >
      {title}
    </p>
    <Line width={lineWidth || null} />
  </TitleContainer>
);

TitleCard.propTypes = {
  title: PropTypes.string.isRequired,
  lineWidth: PropTypes.string,
  fontSize: PropTypes.string,
  marginTop: PropTypes.number,
  maxWidth: PropTypes.number,
};
TitleCard.defaultProps = {
  lineWidth: null,
  marginTop: null,
  maxWidth: null,
  fontSize: null,
};

export default TitleCard;
